@import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css";
/* HOLA JEJE */
a{text-decoration: none !important;}
.cursor-pointer {
  cursor: pointer;
}

.active-slider {
  background-color: #007aff;
  margin: 3px;
  width: 22px;
  border: 0;
  border-radius: 5px;
}
.not-active-slider {
  background-color: #000000;
  margin: 3px;
  width: 22px;
  border: 0;
  border-radius: 5px;
}

@media (max-width: 992px) {
  .active-slider {
    background-color: #007aff;
    margin: 3px;
    width: 22px;
    border: 0;
    border-radius: 5px;
  }
  .not-active-slider {
    background-color: #000000;
    margin: 3px;
    width: 22px;
    border: 0;
    border-radius: 5px;
  }
}
@media (max-width: 767px) {
  .active-slider {
    background-color: #007aff;
    margin: 3px;
    width: 16px;
    border: 0;
    border-radius: 5px;
  }
  .not-active-slider {
    background-color: #000000;
    margin: 3px;
    width: 16px;
    border: 0;
    border-radius: 5px;
  }
}
@media (max-width: 576px) {
  .active-slider {
    background-color: #007aff;
    margin: 3px;
    width: 16px;
    border: 0;
    border-radius: 5px;
  }
  .not-active-slider {
    background-color: #000000;
    margin: 3px;
    width: 16px;
    border: 0;
    border-radius: 5px;
  }
}

.page-item.active_pg .page-link {
  z-index: 3;
  color: #fff;
  background-color: #877cf2 !important;
  border-color: #877cf2 !important;
}
.paymentmethodstypes1___ {
  font-size: 11px;
  text-align: right;
  margin-bottom: 0px;
}
.paymentmethodstypes___ {
  font-size: 10px;
  margin-bottom: 0px;
  text-align: right;
}
/* ----------------------------------- */

.otp{
  border: none !important;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.otp-header {
  color: white !important;
  background: #877cf2 !important;
  text-align: center;
}

.otp-container input{
width: 40px !important;
height: 40px;
}

.otp-control:focus{
  box-shadow: none !important;
  border: 1px solid #877cf2 !important;
}
.varify-btn{
  border-radius: 20px !important;
  border: 0px;
  background-color: #877cf2 !important;
}
.clear-btn{
  border-radius: 20px !important;
  border: 0px;
  background-color: gray;
}


/* ================== */

.city_list_box{
  position: absolute;
  background-color: white;
  width: 93%;
  z-index: 9;
  max-height: 350px;
  overflow: auto;
  /* box-shadow: 1px 1px 6px 1px #808080c4; */
  border-radius: 5px;
  margin-top: 0px;
  border: 1px solid #00000030;
}
.city_list_menu{
  list-style: none;
  padding: 0px;
  /* box-shadow: 1px 1px 5px 1px #8080807d; */
}
.city_list_menu .city_list{
  padding: 5px 15px;
  margin: 3px 2px;
  cursor: pointer;
}
.city_list_menu .city_list:hover{
  background-color: #bef1eea6;
}
.city_list_menu .city_list.active{
  background-color: #bef1eea6;
}
.hide{display: none;}
.show{display: block;}


.searchWrapper > span{
  display:block;
  white-space: pre-wrap;
  height: 35px !important;
  line-height: 13px;
}
.closeIcon{
  margin-top:  5px !important;
  margin-right: 5px !important; 
}
.alignth{
  color:#fff !important;
  background-color:#877cf2!important;
  padding: 10px!important;
  font-family: Arial, Helvetica, sans-serif!important;
  text-align:left!important;
}

.form_chk_input {
  margin-top: 4px !important;
}
.form_chk_input:checked {
  background-color: #877cf2;
  border-color: #877cf2;
}
/* =============================== */

body{background-color: #fff;}

#multiselectContainerReact{
  background-color: white !important;
}
.tbl--row{
  border-top: 2px solid #ededed !important;
  border-bottom: 2px solid #ededed !important;
}
.ser_img_lst{
  width: 100%;
  height: 116px;
  object-fit: cover;
  text-align: center;
}
.ser_img_lst .card-img{
  height: 108px;
  width: auto;  
}
.img_size{
  width:  200px;
  height:  200px;
}